import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './static/fontawesome-pro-5-web/css/all.css';
import App from './App';
import { storesContext } from './contexts';
import stores from './stores';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'use-http'

//document.domain = process.env.REACT_APP_DOMAIN || "lifeinfoapp.com";

//import reportWebVitals from './reportWebVitals';

const options = {
    interceptors: {
        // every time we make an http request, this will run 1st before the request is made
        // url, path and route are supplied to the interceptor
        // request options can be modified and must be returned
        request: async ({ options, url, path, route }) => {
            options.headers.Authorization = `Bearer ${stores.authStore.token || stores.authStore.guestToken}`
            return options
        }
    }
}



if( window.location.host === "conventionprogram.com" || window.location.host === "www.conventionprogram.com")
    window.location = "https://my.lifeinfoapp.com/conventionprogram/login"
else ReactDOM.render(
  <React.StrictMode>
    <storesContext.Provider value={stores}>
      <Router>
          <Provider url={process.env.REACT_APP_API_URL} options={options}>
              <App />
          </Provider>
      </Router>
    </storesContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
